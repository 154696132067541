











































import { Component, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import _ from 'lodash';
import Util from '@/utils/Util';
import EventBus from '@/common/EventBus';
import DefaultProductCard from '@/components/common/product/ProductCard/Default.vue';
import ProductImageProductCard from '@/components/common/product/ProductCard/ProductImage.vue';
import ProductSideImageProductCard from '@/components/common/product/ProductCard/ProductSideImage.vue';
import DetailProductCard from '@/components/common/product/ProductCard/Detail.vue';
import {IRawProducts, ISetPolicyStartDatePayload} from '@/interfaces';
import GAUtil from '@/utils/GAUtil';
import QuoteProduct from '@/models/QuoteProduct';
import GreyOutDetailProductCard from '@/components/common/product/ProductCard/GreyOutDetail.vue';
import moment from 'moment';

const PRODUCTCARD_TYPE = 'ProductCard';

@Component({
  name: 'AddCoverage',
  components: {
    DefaultProductCard,
    ProductImageProductCard,
    ProductSideImageProductCard,
    DetailProductCard,
    GreyOutDetailProductCard
  }
})
export default class AddCoverage extends Vue {
  @State private app: any;
  @State private quotation: any;
  @State private cms: any;
  @Action('quotation/setPolicyDate') private setPolicyDate!: (payload: ISetPolicyStartDatePayload) => void;
  @Action('quotation/createQuotationProduct') private createQuotationProduct!: (productID: string) => any;
  @Getter('quotation/getSortedQuotationProduct') private getSortedQuotationProduct!: () => QuoteProduct[];
  @Getter('products/getProductById') private getProductById: any;
  @Getter('cms/getProductContentByProduct') private getProductContentByProduct: any;
  @Getter('products/getAllProductsWithLatestVersion') private getAllProductsWithLatestVersion!: () => IRawProducts;
  @State private products: any;

  private url: string[] = [];
  private content: any;

  private visible: boolean = false;

  private showAddCoverage() {
    this.$nextTick(() => setTimeout(() => this.visible = true, 1000));
  }
  private created() {
    this.visible = false;
  }

  private mounted() {
    GAUtil.ODLogProductsImpression('configuration', this.unselectedProducts);
    this.showAddCoverage();
  }

  private activate() {
    this.showAddCoverage();
  }

  get isContentReady() {
    return this.app?.isContentReady || false;
  }

  get shiftProducts() {
    return this.getSortedQuotationProduct();
  }

  get isSelectedProductIsExternalPricing() {
    const selectedCodes = _.map(this.quotation.products, 'code');
    const selectedFirstProduct = _.find(this.products, (product) => product.code === selectedCodes[0]);
    return selectedFirstProduct?.allowExternalPricing;
  }

  get unselectedProducts() {
    const selectedCodes = _.map(this.quotation.products, 'code');
    const unselectedProducts = _.filter(this.getAllProductsWithLatestVersion(), (product: any) => {
      const productContent = this.getProductContentByProduct(product);
      if (selectedCodes.length === 0) {
        return true;
      }
      return !selectedCodes.includes(product.code) && Object.keys(productContent).length !== 0 &&  !product.allowExternalPricing;
    });
    return _.sortBy(unselectedProducts, [(product) => {
      return _.get(this.getProductContentByProduct(product), 'index', 0);
    }]);
  }

  get addCoverageIcon() {
    return '/coverhub-portal/images/add-coverage.svg';
  }

  get getCardType() {
    return this.cms.layout.cardType + PRODUCTCARD_TYPE;
  }

  private activated() {
    this.formatCards();
  }

  private formatCards() {
    const regx = new RegExp('\\b' + 'row-number-' + '(.*)?\\b', 'g');
    this.$nextTick(() => {

      let row = 0;
      let lastOffset = -1;

      const products = [...document.querySelectorAll('.product')];

      products.forEach((product) => {
        product.className = product.className.replace(regx, '');
      });

      products.forEach((product, key) => {
        const productName = (product.querySelector('.product-name') as HTMLElement);
        const premium = (product.querySelector('.premium') as HTMLElement);
        if (productName) {
          productName.style.height = 'auto';
        }
        if (premium) {
          premium.style.height = 'auto';
        }

        if (lastOffset < (product as HTMLElement).offsetTop) {
          Util.getSameHeight([...document.querySelectorAll('.row-number-' + row)]);
          row++;
          lastOffset = (product as HTMLElement).offsetTop;
        }

        product.className = product.className.replace(regx, '');
        product.classList.add('row-number-' + row);

        if (key === products.length - 1) {
          Util.getSameHeight([...document.querySelectorAll('.row-number-' + row)]);
        }
      });
    });
  }

  private getIcon(product: any) {
    const cmsProduct = this.getProductContentByProduct(product);
    if (_.get(cmsProduct, 'additionalInformation[0].file.url')) {
      this.url[product.code] = cmsProduct.additionalInformation[0].file.url;
    }
    return _.get(cmsProduct, 'icon') && 'https://' + cmsProduct.icon.replace(/^.*\/\//, '');
  }

  private getTitle(product: any) {
    const cmsProduct = this.getProductContentByProduct(product);
    return _.get(cmsProduct, 'title', '');
  }

  private addProduct(product: any, index: number) {
    EventBus.$emit('open-loading-dialog');
    setTimeout(() => {
      this.createQuotationProduct(product.code).then(() => {
        EventBus.$emit('close-loading-dialog');
        _.forEach(this.shiftProducts, (shiftProduct: QuoteProduct) => {
          if (shiftProduct.code === product.code) {
            GAUtil.ODLogAddProduct(shiftProduct, index, 'configuration');
            GAUtil.ODLogSelectedProducts([shiftProduct]);
            return false;
          }
        });

        // update covaerage start date if copy coverage date enabled
        if (this.app.copyCoverageDate && this.shiftProducts.length > 0) {
          this.setPolicyDate({productID: product.id, date: this.shiftProducts[0].coverageStartDate || moment().format('YYYY-MM-DD') });
        }
      });

      this.$global.enterAnimation();
    }, 100);
  }

  private onInfoBtnClick(content: any) {
    this.$emit('onInfoBtnClick', content);
  }
}
